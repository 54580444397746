<template>
    <div class="d-flex flex-column min-vh-100">
        <Header :profile="profile"/>
        <div class="subheader">
           <h3>{{post.title}}</h3>
        </div>
        <div class="container" style="margin-top:30px">
            <div class="row">
                <div class="col-md-8 col-sm-12">
                    <p>
                    <a class="btn btn-outline-dark" href=".."><i class="fa fa-chevron-left"></i> Regresar</a>
                    </p>
                    <img :src="`/storage/posts/${post.cover_image}`" alt="" class="img-fluid">
                    <div v-html="post.content" class="my-5"></div>
                    <div class="shareFacebook my-5">
                        <ShareNetwork
                            network="facebook"
                            :url="`${appURL}'blog/post/${postId}`"
                            :title="post.title"
                            :description="post.content.replace(/(<([^>]+)>)/gi, '')"
                            hashtags="tmtcapacitacion"
                            :media="`${appURL}storage/posts/${post.cover_image}`"
                        >
                            Compartir en facebook
                        </ShareNetwork>
                    </div>

                </div>


            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
//quote="The hot reload is so fast it\'s near instant. - Evan You"
//description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';

export default {
  components:{
      Header,
      Footer
  },
  name: 'AboutUs',
  data() {
      return {
          profile: this.$store.getters.getUser,
          postId: this.$route.params.postId,
          post: {},
          appURL: process.env.VUE_APP_URL
      }
  },
  mounted(){
      this.loadPost(this.postId);
  },
  methods:{
      loadPost(postId) {
          axios.get(process.env.VUE_APP_APIURL + 'posts/' + postId)
            .then((response)=>{this.post = response.data.data})
      }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-darkBlue{
    background: #152F44
}
.btn-black{ background:#000; color:#FFF; font-size:14px; border:1px solid #979797;}
.btn-black:hover{color:#FFF; background:#333232}
.btn-golden{ background:#AA8D5D; color:#FFF; font-size:14px; border:1px solid #9B7676;}
.btn-golden:hover{color:#FFF; background:#C4A167}

.navbar-light .navbar-nav .nav-link{ color:#FFF}
.navbar-light .navbar-nav .nav-link:hover{ background:#C4A167}
.btn-outline-white{
    border:1px solid #FFF;
    color:#FFF
}
.carousel-caption {top:30%}
.carousel-caption h5{ margin-bottom:30px}
.section{margin:60px 0}
footer{margin-top:60px}

.subheader{ background:#D1C1A7; color:#FFF; display: flex;
  align-items: center;
  justify-content: center; height:250px}

  .shareFacebook {
      background: #0A7EEA;
    height: 40px;
    line-height: 30px;
    padding: 5px 8px;
    color: #FFF;
    border-radius: 5px;
    width:200px
  }
  .shareFacebook a {color:#FFF}
</style>
